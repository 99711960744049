import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

type Props = {
  icon?: string;
  type: "category" | "account" | "payee";
  size?: number;
};

const EntityIcon = ({ icon, type, size = 18 }: Props) => {
  return icon ? (
    <img
      className="mr-1"
      src={icon}
      style={{ width: `${size}px`, height: `${size}px`, display: "inline" }}
    />
  ) : type === "category" ? (
    <Icon className="mr-1" icon={IconNames.CUBE} size={size} />
  ) : type === "account" ? (
    <Icon className="mr-1" icon={IconNames.BANK_ACCOUNT} size={size} />
  ) : (
    <Icon className="mr-1" icon={IconNames.THIRD_PARTY} size={size} />
  );
};

export default EntityIcon;
