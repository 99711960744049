import { Callout, Card, Intent } from "@blueprintjs/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import MoneyValue from "../common/MoneyValue";
import { DateTime } from "luxon";
import { useQuery } from "@apollo/client";
import { ENTRIES_FROM_PERIOD } from "../../graphql/queries/entry";
import { parseDate } from "../../utils/utils";
import { getTotalSpendingsFromEntries } from "../../utils/budgetUtils";
import WithLoadingSpinner from "../common/WithLoadingSpinner";

interface ILabel {
  label: string;
  value: number;
}

const Label = ({ label, value }: ILabel) => {
  return (
    <div className="flex flex-row">
      <div className="mr-1 font-semibold">{label}</div>
      <div>
        <MoneyValue value={value} />
      </div>
    </div>
  );
};

interface Props {
  selectedDate: DateTime;
  totalIncome: number;
  totalPlannedSpendingsInCurrentMonth: number;
}

const BudgetSummary = ({
  selectedDate,
  totalIncome,
  totalPlannedSpendingsInCurrentMonth,
}: Props) => {
  const leftToDistribute = totalIncome - totalPlannedSpendingsInCurrentMonth;
  const { t } = useTranslation();

  const { data: entriesFromThisMonth, loading } = useQuery(ENTRIES_FROM_PERIOD, {
    variables: {
      periodStart: parseDate(selectedDate).startOf("month"),
      periodEnd: parseDate(selectedDate).endOf("month"),
    },
  });

  const totalSpendingsInCurrentMonth = useMemo(() => {
    return getTotalSpendingsFromEntries(entriesFromThisMonth ? entriesFromThisMonth.entries : []);
  }, [entriesFromThisMonth]);

  return (
    <Card className="shadow p-0 mb-2 w-full">
      <Callout
        intent={
          leftToDistribute === 0
            ? Intent.SUCCESS
            : leftToDistribute > 0
            ? Intent.PRIMARY
            : Intent.DANGER
        }
      >
        <div className="md:flex md:flex-row justify-between">
          <Label label={t("incomes.total_income")} value={totalIncome} />
          <Label label={t("incomes.total_planned")} value={totalPlannedSpendingsInCurrentMonth} />
          <Label label={t("incomes.left_to_distribute")} value={leftToDistribute} />
          <WithLoadingSpinner isLoading={loading}>
            <Label label={t("incomes.total_spendings")} value={totalSpendingsInCurrentMonth} />
          </WithLoadingSpinner>
        </div>
      </Callout>
    </Card>
  );
};

export default BudgetSummary;
