import { Callout, Card } from "@blueprintjs/core";
import { IIncome } from "../../types/types";
import Actions from "../common/Actions";
import React from "react";
import MoneyValue from "../common/MoneyValue";

type Props = {
  income: IIncome;
  openDeleteDialog: (income: IIncome) => void;
  onEdit: (arg0: any) => void;
};

const MobileIncome = ({ income, openDeleteDialog, onEdit }: Props) => {
  return (
    <Card className="shadow p-0 mb-2">
      <Callout className="my-1 py-2">
        <div className="flex flex-row items-center">
          <h5 className="bp5-heading bold flex-grow">{income.name}</h5>
          <span className="font-semibold text-md inline-block mr-1">
            <MoneyValue value={income.value} />
          </span>
          <span className="font-semibold text-md inline-block mr-1">/</span>
          <span className="font-semibold text-md inline-block mr-4">
            <MoneyValue value={income.actual_value} />
          </span>
          <Actions entry={income} onDelete={openDeleteDialog} onEdit={onEdit} />
        </div>
      </Callout>
    </Card>
  );
};

export default MobileIncome;
