import { useMutation } from "@apollo/client";
import { Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { INLINE_UPDATE_ENTRY } from "../../graphql/mutations/entry";
import { IAccount, ICategory, IEntry, IPayee } from "../../types/types";
import { graphQlError } from "../../utils/utils";
import { Notifications } from "../common/notifications";
import AccountSelect from "./AccountSelect";
import EntityIcon from "./EntityIcon";
import InternalLink from "./InternalLink";
import PayeeSelect from "./PayeeSelect";

type Props<T> = {
  entry: IEntry;
  entryToEdit?: T;
  editingKey: "account" | "category" | "payee";
};

const EditableCellWithSelect = <T extends IAccount | IPayee | ICategory>({
  editingKey,
  entry,
  entryToEdit,
}: Props<T>) => {
  const [displayEditComponent, setDisplayEditComponent] = useState<"none" | "temp" | "visible">(
    "none"
  );
  const [updateEntry] = useMutation(INLINE_UPDATE_ENTRY);
  const { t } = useTranslation();
  const _onChange = (key: string, value: T) => {
    updateEntry({
      variables: {
        id: entry.entryId || entry.id,
        changes: {
          [key]: value.id,
        },
      },
    })
      .then((res) => {
        res &&
          Notifications &&
          Notifications.show({ message: t("messages.updated"), intent: Intent.SUCCESS });
      })
      .catch(graphQlError)
      .finally(closeEditing);
  };

  const _onAccountChange = (account: IAccount) => {
    _onChange("account_id", account as T);
  };

  const onChangePayee = (payee: IPayee) => {
    _onChange("payee_id", payee as T);
  };

  const closeEditing = useCallback(() => {
    setDisplayEditComponent("none");
  }, []);

  const [displayComponent, editComponent] = useMemo(() => {
    if (editingKey === "account") {
      return [
        <LinkWithIcon key={editingKey + "_EDIT"} editingKey={editingKey} entry={entry.account} />,
        <AccountSelect
          fill={true}
          disabled={entry.transfer}
          key={editingKey + "_SELECT"}
          onChange={_onAccountChange}
          popoverProps={{
            onOpened: () => setDisplayEditComponent("visible"),
          }}
          initialAccount={entryToEdit as IAccount}
        />,
      ];
    } else {
      return [
        <LinkWithIcon key={editingKey + "_EDIT"} editingKey={editingKey} entry={entry.payee} />,
        <PayeeSelect
          key={editingKey + "_SELECT"}
          fill={true}
          disabled={false}
          onChange={onChangePayee}
          popoverProps={{
            onOpened: () => setDisplayEditComponent("visible"),
          }}
          initialPayee={entryToEdit as IPayee}
        />,
      ];
    }
  }, [editingKey, entry]);

  return (
    <div className="with-inline-edit-icon flex flex-row">
      {displayEditComponent !== "none" ? (
        <>
          <div className="flex-1">{editComponent}</div>
          <Icon className="ml-1 inline-edit-icon" icon={IconNames.CROSS} onClick={closeEditing} />
        </>
      ) : (
        <>
          <div className="flex-1">{displayComponent}</div>
          <Icon
            className="ml-1 inline-edit-icon"
            icon={IconNames.EDIT}
            onClick={() => setDisplayEditComponent("visible")}
          />
        </>
      )}
    </div>
  );
};

interface ILinkProps {
  editingKey: "account" | "category" | "payee";
  entry?: Pick<IAccount | IPayee, "icon" | "id" | "name">;
}

export const LinkWithIcon = ({ editingKey, entry }: ILinkProps) => {
  return entry ? (
    <InternalLink key={editingKey + "_EDIT"} to={`/${editingKey}s/${entry.id}`}>
      <EntityIcon icon={entry.icon} type={editingKey} /> {entry.name}
    </InternalLink>
  ) : (
    ""
  );
};

export default EditableCellWithSelect;
